import React, { FC } from "react"

import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml"
import { IProductBannerTitle } from "gatsby-theme-nurofen/src/components/ProductBanner/ProductBannerTitle/model"

const ProductBannerTitle: FC<IProductBannerTitle> = ({
  title,
}): React.ReactElement => (
  <DangerouslySetInnerHtml
    element="h1"
    className="nf-product-banner__product-title"
    html={title}
  />
)

export default ProductBannerTitle
